import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/font/font.css'

import * as echarts from 'echarts'
import SC from "@/assets/geo/SC"

echarts.registerMap('SC', SC)

import Element from 'element-ui'
import '@/styles/element-variables.scss'
import 'font-awesome/css/font-awesome.css'
import 'simplemde/dist/simplemde.min.css'
import 'highlight.js/styles/github-dark.css'
import '@/styles/app.scss'

Vue.use(Element)

import * as directives from './directives'

Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})

import filters from "@/utils/filters"

for (let key in filters) {
  Vue.filter(key, filters[key])
}

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
  router, store, render: h => h(App)
}).$mount('#app')
