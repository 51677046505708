// v-if、v-show 与 v-permission 冲突
// v-if="you_condition && checkPermission('you_permission')"
// v-permission="'you_permission'"
import store from '@/store'

// eslint-disable-next-line no-unused-vars
export default function (permission) {
  if (!store.state.auth.user) {
    return false
  }
  //TODO check user has permission
  return false
}