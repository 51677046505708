import {BASE_URI} from "@/utils/config"

export default {
  getSourceUri(path) {
    if (/^http(s)?:\/\/.+/.test(path)) {
      return path
    }
    if (/^\/\/.+/.test(path)) {
      return path
    }
    return BASE_URI + (/^\//.test(path) ? path : '/' + path)
  },
  getSourceUris(paths) {
    let uris = []
    for (let i in paths) {
      uris.push(this.getSourceUri(paths[i]))
    }
    return uris
  }
}

// export function recursiveFilter(arr, value) {
//   return arr.filter(item => {
//     if (item.id === value) {
//       return false
//     }
//     if (item.children && item.children.length > 0) {
//       item.children = recursiveFilter(item.children, value)
//     }
//     return true
//   })
// }

export function taskStateName(state) {
  switch (String(state)) {
    case 'WAIT':
      return '待接单'
    case 'SURVEYED':
      return '待踏勘'
    case 'EXAMINE':
      return '审核中'
    case 'SUCCESS':
      return '审核成功'
    case 'WORKING':
      return '施工中'
    case 'OVER':
      return '验收通过'
    case 'UPLOAD':
      return '已提并网'
    case 'WAITING_SIGN':
      return '待签约'
    case 'FAIL':
      return '审核失败'
    case 'CLOSE':
      return '封单'
    case 'WAIT_RECEIVE':
      return '待验收'
    default:
      return '-'
  }
}