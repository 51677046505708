export default [
  {
    path: '/admin/organization',
    name: 'Organizations',
    component: () => import('./Organizations.vue'),
    meta: {title: '组织管理',parent: '系统设置'},
  },
  {
    path: '/admin/role',
    name: 'Roles',
    component: () => import('./Roles.vue'),
    meta: {title: '角色管理',parent: '系统设置'},
  },
  {
    path: '/admin/role-info/:id',
    name: 'RoleInfo',
    component: () => import('./RoleInfo.vue'),
    meta: {title: '角色详情',parent: '系统设置'},
  },
  {
    path: '/admin/role-add',
    name: 'RoleAdd',
    component: () => import('./RoleAdd.vue'),
    meta: {title: '新增角色',parent: '系统设置'},
  },
  {
    path: '/admin/authority',
    name: 'Authorities',
    component: () => import('./Authorities.vue'),
    meta: {title: '权限管理',parent: '系统设置'},
  },
  {
    path: '/admin/authority/:id',
    name: 'AuthorityEdit',
    component: () => import('./AuthorityEdit.vue'),
    meta: {title: '编辑权限',parent: '系统设置'},
  },
  {
    path: '/admin/add-staff',
    name: 'AddStaff',
    component: () => import('./AddStaff.vue'),
    meta: {title: '新增员工',parent: '系统设置'},
  },
]
