export const APP_NAME = '新能源户用光伏开发管理平台'

export const BASE_URI = '/api'

export const COMPANY_NAME = '新能源'

export const PIC_URL = 'https://ln.followsunny.com/api'

export const PROVINCE_CODES = [
  {id:11102, name:'北京供电公司',province:'北京市'},
  {id:12101, name:'天津市电力公司',province:'天津市'},
  {id:13102, name:'河北省电力公司',province:'河北省'},
  {id:13103, name:'冀北电力公司',province:'河北省'},
  {id:14101, name:'山西省电力公司',province:'山西省'},
  {id:15101, name:'内蒙古东部电力有限公司',province:'内蒙古自治区'},
  {id:21102, name:'辽宁省电力有限公司',province:'辽宁省'},
  {id:22101, name:'吉林省电力有限公司',province:'吉林省'},
  {id:23101, name:'黑龙江省电力有限公司',province:'黑龙江省'},
  {id:31102, name:'上海市电力公司',province:'上海市'},
  {id:32101, name:'江苏省电力公司',province:'江苏省'},
  {id:33101, name:'浙江省电力公司',province:'浙江省'},
  {id:34101, name:'安徽省电力公司',province:'安徽省'},
  {id:35101, name:'福建省电力有限公司',province:'福建省'},
  {id:36101, name:'江西省电力公司',province:'江西省'},
  {id:37101, name:'山东省电力集团公司',province:'山东省'},
  {id:41101, name:'河南省电力公司',province:'河南省'},
  {id:42102, name:'湖北省电力公司',province:'湖北省'},
  {id:43101, name:'湖南省电力公司',province:'湖南省'},
  {id:50101, name:'重庆市电力公司',province:'重庆市'},
  {id:51101, name:'四川省电力公司',province:'四川省'},
  {id:54101, name:'西藏区电力有限公司',province:'西藏自治区'},
  {id:61102, name:'陕西省电力公司',province:'陕西省'},
  {id:62101, name:'甘肃省电力公司',province:'甘肃省'},
  {id:63101, name:'青海省电力公司',province:'青海省'},
  {id:64101, name:'宁夏区电力公司',province:'宁夏回族自治区'},
  {id:65101, name:'新疆区电力公司',province:'新疆维吾尔自治区'},
]

export const FARMER_TYPES = [
  {label:  '农户', value: 0},
  {label:  '公共屋顶', value: 1},
]

export const USER_STATE = [
  {label:  '已实名', value: 1},
  {label:  '未实名', value: 0},
]
