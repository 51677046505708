<template>
  <div class="navbar-container">
    <div class="navbar-title-wrap" style="display: flex; align-items: center;">
      <div class="navbar-title" style="line-height: 48px">{{app_name}}</div>
    </div>
    <div class="navbar-right-wrap">
      <el-menu class="navbar-right-menu" mode="horizontal">
        <el-submenu index="1" style="width: 150px;">
          <template slot="title"><span style="color: #fff;">{{ user.name }}</span></template>
     <!--     <el-menu-item @click.native="goHome" index="1-1">首页</el-menu-item>-->
          <el-menu-item @click.native="logout()" index="1-2">退出</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import {APP_NAME} from "@/utils/config"

export default {
  name: "Navbar",
  data() {
    return {
      app_name: APP_NAME
    }
  },
  components: {},
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    goHome() {
      this.$router.replace('/admin/index')
    },
    logout() {
      let that = this
      that.$store.dispatch('auth/logout').then(() => {
        that.$router.replace('/admin/login')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-container {
  padding: 0 20px;
  height: 48px;
  background-color: #141414;
  color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: unset;

  }

  .navbar-right-menu {
    & /deep/ .el-menu-item, .el-submenu {
      height: 48px;
      line-height: 48px;
      background-color: #141414;
    }

    & /deep/ .el-submenu__title {
      height: 48px;
      line-height: 48px;
      border-bottom: unset;
      color: #FFFFFF;
    }

    & /deep/ .el-submenu__title:hover {
      color: #FFFFFF;
    }
  }
}
</style>
