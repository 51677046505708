export default [
  {
    path: '/admin/service-farmer',
    name: 'Farmers',
    component: () => import('./Farmers.vue'),
    meta: {title: '农户管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-station',
    name: 'Stations',
    component: () => import('./Stations.vue'),
    meta: {title: '建站管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-on-grid',
    name: 'Connections',
    component: () => import('./Connections.vue'),
    meta: {title: '并网管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-order',
    name: 'Orders',
    component: () => import('./Orders.vue'),
    meta: {title: '工单管理',parent: '业务管理'},
  },
]