import {getUser} from '@/api/auth'

const AUTHORIZATION_KEY = 'ADMIN_AUTHORIZATION'

const state = () => ({
  user: null,
  authorization: localStorage.getItem(AUTHORIZATION_KEY),
})

const getters = {}

const actions = {
  login({commit}, {authorization, user}) {
    commit('setAuthorization', authorization)
    commit('setUser', user)
  },
  autoLogin({commit, state}) {
    return new Promise((resolve, reject) => {
      if (!state.authorization) {
        reject()
      } else if (state.authorization && !state.user) {
        getUser().then(res => {
          commit('setUser', res)
          // console.log('setUser', state.user)
          resolve()
        }).catch(() => {
          commit('unsetUser')
          commit('unsetAuthorization')
          reject()
        })
      } else {
        resolve()
      }
    })
  },
  logout({commit}) {
    return new Promise((resolve) => {
      commit('unsetUser')
      commit('unsetAuthorization')
      resolve()
    })
  },
  refreshToken({commit}, authorization) {
    commit('setAuthorization', authorization)
  }
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  unsetUser(state) {
    state.user = null
  },
  setAuthorization(state, authorization) {
    state.authorization = authorization
    localStorage.setItem(AUTHORIZATION_KEY, authorization)
  },
  unsetAuthorization(state) {
    state.authorization = null
    localStorage.removeItem(AUTHORIZATION_KEY)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
